import AboutStyles from "../About.module.css";
const parentheses = AboutStyles.parentheses;
const dash = <span className={parentheses}>-</span>;

const MeetTeamData = [
  {
    id: 0,
    title: "WE HAVE MOVED",
    body: (
      <>
        Please click <a href="https://accesscoordinatorbootcamp.com/"> HERE </a>{" "}
        to visit our new location:
      </>
    ),
    bodyExt: <></>,
  },
];

export default MeetTeamData;
